import React from 'react'
import SectionContainer from '../SectionContainer'
import styled from 'styled-components'
import { screen } from '../../../utils/'
import tw from 'tailwind.macro'

const TripleCardWrapper = styled.div`
  color: #242424;
  flex-direction: colum;
  padding: 0 0 70px;

  ${screen('lg')} {
    flex-direction: column;
  }
`;

const Title = styled.h2`
${tw`
    mb-2
    md:mb-3
    mt-5
    md:mt-5
    font-sans   
    font-bold  
    leading-relaxed   
    text-31-1/2px  
    text-charcoal   
    md:text-40px
  ` }
`;

const Subtitle = styled.p`
  display: flex;
  ${tw`
    font-sans   
    font-bold  
    text-charcoal   
    leading-relaxed   
    text-xl
    md:text-23px
    pb-1
    md:pb-2
  ` }

  ${screen('lg')} {
    flex-direction: column;
  }
`;

const Cards = styled.div`
  display: block;


  ${screen('md')} {
    display: flex;
  }
`;

const CardItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  background: white;

  ${screen('md')} {
    justify-content: space-between;
    width: 100%;
    margin: 0 7px;
    
    &:first-of-type {
      margin-left: 0;
    }
    
    &:last-of-type {
      margin-rigth: 0;
    }
  }
`;

const CardItem = styled.div`
  display: block;
`;

const CardItemImage = styled.div`
  position: relative;

  img {
    width: 100%;
    max-height: 125px;
    object-fit: cover;
  }

  ${screen('lg')} {
    img {
      width: 100%;
      max-height: 125px;
      object-fit: cover;
  }
`;


const CardItemTitle = styled.div`
  ${tw`
    mb-2
    py-2
    pl-3
    text-lg
    font-bold 
    color-charcoal
  ` }
`;

const CardItemDescription = styled.div`
  padding: 0 10px 0 20px;

  ${tw`
      mb-6 
      font-sans   
      font-thin   
      leading-relaxed   
      text-base   
      text-charcoal   
    ` }
`;

const ImageDot = styled.span`
  position: absolute;
  height: 15px;
  width: 15px;
  background: purple;
  top: 5px;
  left: 5px;
  border-radius: 50%;
`;

const CardAuthor = styled.div`
  display: flex;
  margin-left: 20px;
  padding: 30px 0 20px;
  color: #242424;
`;

const CardAuthorImage = styled.div`
  position: relative;
  img {
    width: 30px;
    height: 30px;
    object-fit: cover;
    border-radius: 50%;
    border: 1px solid #ccc;
  }
`;

const CardAuthorImageDot = styled.span`
  position: absolute;
  height: 8px;
  width: 8px;
  background: purple;
  top: 1px;
  right: 0;
  border-radius:50%;
`;

const CardAuthorInfo = styled.div`
  padding-top: 3px;

  p{
    display: block;
    padding-left: 12px;
    items-aling: center;
    font-size: 12px;
    padding-top: 1px;
    color: #242424;
  }
`;

const CardAuthorCompany = styled.div`
  color: purple;
`;


const TripleCard = ({
  title,
  subtitle,
  items
}) => (
  <SectionContainer>
    <TripleCardWrapper>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
      <Cards>
        {items.map((item, index) => (
          <CardItemContainer className="card-shadow" key={`item_${index}`}>
            <CardItem>
              <CardItemImage>
                <img src={item.image} />
                <ImageDot />
              </CardItemImage>
              <CardItemTitle>
                {item.link ? (
                  <a
                    href={item.link.url}
                    target={item.link.target}
                    className="no-underline text-charcoal hover:no-underline text-charcoal"
                  >
                    {item.title}
                  </a>
                ) : item.title}
              </CardItemTitle>
              <CardItemDescription>
                {item.link ? (
                  <a
                    href={item.link.url}
                    target={item.link.target}
                    className="no-underline text-charcoal hover:no-underline text-charcoal"
                  >
                    {item.description}
                  </a>
                ) : item.description}
              </CardItemDescription>
            </CardItem>
            <CardAuthor>
              <CardAuthorImage>
                <img src={item.author.picture} />
                <CardAuthorImageDot />
              </CardAuthorImage>
              <CardAuthorInfo>
                <p>{item.author.name}</p>
                <CardAuthorCompany>
                  <p>{item.author.company}</p>
                </CardAuthorCompany>
              </CardAuthorInfo>
            </CardAuthor>
          </CardItemContainer>
        ))}
      </Cards>
    </TripleCardWrapper>
  </SectionContainer>
)

export default TripleCard
