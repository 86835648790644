import React from 'react'
import NFTPage from '../components/Templates/NFT'
import { graphql } from 'gatsby'
import { withPreview } from 'gatsby-source-prismic'
import NoTranslation from '../components/Templates/NoTranslation'

const NftPage = ({ data, pageContext }) => {
  if (!data.prismicNft) return <NoTranslation></NoTranslation>
  return <NFTPage data={data} pageContext={pageContext}></NFTPage>
}

export default withPreview(NftPage)

export const pageQuery = graphql`
  query NftPageQuery($locale: String!) {
    prismicNft(lang: { eq: $locale }) {
      data {
        meta_title {
          text
        }
        meta_description
        body {
          ... on PrismicNftBodyHero {
            id
            primary {
              description {
                text
              }
              title {
                text
              }
              subtitle {
                text
              }
            }
          }
          ... on PrismicNftBodyTextWithIcons {
            id
            primary {
              text {
                text
              }
            }
            items {
              icon {
                alt
                url
              }
              title {
                text
              }
            }
          }
          ... on PrismicNftBodyStoryCardFeed {
            id
            items {
              description {
                text
              }
              title {
                text
              }
            }
            primary {
              heading {
                text
              }
            }
          }
          ... on PrismicNftBodyTwoButtons {
            id
            items {
              title {
                text
              }
              description {
                text
              }
              button {
                target
                url
              }
            }
          }
          ... on PrismicNftBodyFourUpCards {
            id
            items {
              description {
                html
              }
              image {
                alt
                url
              }
            }
            primary {
              description {
                text
              }
              title {
                text
              }
            }
          }
          ... on PrismicNftBodyThreeUpCards {
            id
            items {
              author_company {
                text
              }
              link {
                url
                target
              }
              author_name {
                text
              }
              author_picture {
                alt
                url
              }
              description {
                text
              }
              image {
                alt
                url
              }
              title {
                text
              }
            }
            primary {
              description {
                text
              }
              title {
                text
              }
            }
          }
          ... on PrismicNftBodyTextWithColumns {
            id
            items {
              list {
                raw
              }
              title {
                text
              }
            }
            primary {
              description {
                html
              }
              title {
                text
              }
            }
          }
          ... on PrismicNftBodyFiveUpCards {
            id
            items {
              image {
                url
                alt
              }
              link {
                url
                target
              }
            }
            primary {
              description {
                html
              }
              title {
                text
              }
            }
          }
          ... on PrismicNftBodyAuthorQuote {
            id
            primary {
              author_picture {
                alt
                url
              }
              link {
                url
                target
              }
              name {
                text
              }
              quote {
                text
              }
            }
          }
          ... on PrismicNftBodyHubspotEmbed {
            id
            primary {
              form_id
            }
          }
        }
      }
    }
  }
`
