import styled from 'styled-components'
import { screen } from '../../../utils/'

export const TextWithIconsWrapper = styled.div`
  background: rgb(168,26,88);
  background: linear-gradient(315deg, rgba(168,26,88,1) 0%, rgba(187,73,65,1) 100%);
  padding: 60px 0 10px;
  margin-left: -24px;
  margin-right: -24px;
  padding-left: 24px;
  padding-right: 24px;

  ${ screen('xl') } {
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
  }
`;

export const Text = styled.p`
  text-align: center;
  color: #fff;
  font-size: 15px;
  line-height: 28px;
  padding:0;

  ${ screen('lg') } {
    font-size: 17.5px;
    line-height: 30.5px;
    padding: 0 80px;
  }
`;

export const IconsList = styled.ul`
  display: flex;
  list-style: none;
  display: flex;
  margin: 40px 0;
  padding: 0;
  align-items: center;
  flex-wrap: wrap;

  ${ screen('sm') } {
    justify-content: space-between;
    flex-direction: row;
    padding: 0 20px;
  }

  ${ screen('md') } {
    padding: 0 150px;
    margin-top: 45px;
    flex-direction: row;
  }
`;

export const IconItem = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: #fff;
  padding: 0 ;
  width: 50%; 
  min-width: fit-content;

  ${ screen('md') } {
    margin: 0 ;
    width:20%;
    min-width: fit-content;
  }
`;

export const Icon = styled.img`
  max-height: 28px;
  object-fit: contain;
  color: #fff;
  margin-bottom:5px

  ${ screen('lg') } {
    margin-bottom: 8px;
  }
`;

export const Label = styled.p`
  font-size: 14px;
  line-height: 20px;
  font-weight: lighter;
  text-align: center;
  padding-bottom:40px;
  letter-spacing:0.5px;
  margin-top:5px;

  ${ screen('md') } {
    font-size: 15px;
    line-height: 22px;
    margin-bottom:0;
  }

  ${ screen('lg') } {
    letter-spacing: 1.0px;
    font-size: 16.5px;
    line-height: 24px;
    margin-bottom:0;
    margin-top:8px
  }
`;
