import React from 'react'
import {

  TextBlockWithColumnsWrapper,
  TextBlockWithColumnsWrapperUp,
  TextBlockWithColumnsWrapperTitle,
  TextBlockWithColumnsWrapperDescription,
  TextBlockWithColumnsWrapperDown,
  TextBlockWithColumnsWrapperLeft,
  TextBlockWithColumnsWrapperTitleList,
  TextBlockWithColumnsWrapperList,
  TextBlockWithColumnsWrapperRight
} from './blocks'

const TextBlockWithColumns = ({ title, description, columns }) => {

  const getColumnData = (listIndex) => {
    return columns[listIndex];
  }

  return (
  <TextBlockWithColumnsWrapper>
    <TextBlockWithColumnsWrapperUp>
      <TextBlockWithColumnsWrapperTitle>
        {title}
      </TextBlockWithColumnsWrapperTitle>
      <TextBlockWithColumnsWrapperDescription dangerouslySetInnerHTML={{ __html: `<div>${description}</div>` }}>
      </TextBlockWithColumnsWrapperDescription>
    </TextBlockWithColumnsWrapperUp>
    <TextBlockWithColumnsWrapperDown>
      <TextBlockWithColumnsWrapperLeft>
        <TextBlockWithColumnsWrapperTitleList>
          <h4>{getColumnData(0).title}</h4>
        </TextBlockWithColumnsWrapperTitleList>
        <TextBlockWithColumnsWrapperList>
          <ul>
            {getColumnData(0).list.map((columnItem, index) => (
              <li key={`item_${index}`}>{columnItem.text}</li>
            ))}
          </ul>
        </TextBlockWithColumnsWrapperList>

      </TextBlockWithColumnsWrapperLeft>
      <TextBlockWithColumnsWrapperRight>
        <TextBlockWithColumnsWrapperTitleList>
          <h4>{getColumnData(1).title}</h4>
        </TextBlockWithColumnsWrapperTitleList>
          <TextBlockWithColumnsWrapperList>
            <ul>
              {getColumnData(1).list.map((columnItem, index) => (
                <li key={`item_${index}`}>{columnItem.text}</li>
              ))}
            </ul>
          </TextBlockWithColumnsWrapperList>
      </TextBlockWithColumnsWrapperRight>
    </TextBlockWithColumnsWrapperDown>
  </TextBlockWithColumnsWrapper>)
}

export default TextBlockWithColumns
