import styled from 'styled-components'
import { screen } from '../../../utils/index'

export const SectionContainerAuthorQuoteLink = styled.div`
  flex-direction: column;
  display: flex;
  align-item: center;

  ${ screen('md') } {
    flex-direction: row;
  }
`;

export const SectionContainerImage = styled.div`
  margin: 0;
  items-aling: center;
  object-fit: contain;
  width: 100%;

  ${ screen('lg') } {
    img{
      max-width: 550px;
      max-height: 244px;
      max-width: 650px;
    }
  }  
`;

export const SectionContainerTextAuthor = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 15px 0;

  ${ screen('lg') } {
    padding: 40px 20px 0;
    max-width: 500px;
  }  
`;

export const SectionContaierTextAuthorQuote = styled.div``;

export const SectionContainerQuote = styled.div`
  font-family: Overpass, sans-serif;
  font-size: 18px;
  line-height: 28px;
  font-weight: bold;
  text-align: left;

  ${ screen('lg') } {
    font-size: 24px;
    line-height: 38px;
  }  
`;

export const SectionContainerAuthor = styled.div `
  font-family: Overpass, sans-serif;
  font-size: 12px;
  line-heigth: 28px;
  font-weight: lighter;
  text-align: right;
  margin-bottom: 20px;
  margin-top: 8px;

  ${ screen('lg') } {
    font-size: 15px;
    line-height: 38px;
    margin-top: 20px;
    margin-bottom: 40px;
  }
`;

export const SectionContainerLink = styled.div ``;