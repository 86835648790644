import React from 'react'
import SectionContainer from '../SectionContainer'
import {
  TextWithIconsWrapper,
  Text,
  IconsList,
  IconItem,
  Icon,
  Label
} from './blocks'

const TextWithIcons = ({ text, items }) => (
  <TextWithIconsWrapper>
    <SectionContainer>
      <Text>{text}</Text>
      <IconsList>
        {items.map((item, index) => (
          <IconItem key={`item_${index}`}>
            <Icon src={item.icon}></Icon>
            <Label>{item.label}</Label>
          </IconItem>
        ))}
      </IconsList>
    </SectionContainer>
  </TextWithIconsWrapper>
)

export default TextWithIcons
