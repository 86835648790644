import { screen } from '../../../utils/'
import styled from 'styled-components'
import tw from 'tailwind.macro'

export const TextBlockWrapper = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;
`

export const TextBlockSubtitle = styled.div`
${tw`
  font-overpass
  font-bold
  leading-relaxed
  text-lg
  md:text-23px
  tracking-wider
  color-charcoal
  text-center
  mb-4
  md:mb-6
  md:text-23px
`}

`;

export const TextBlockTitle = styled.p`
  color:#242424;
  text-align: center;
  margin:0 auto;
  margin:0 22px;
  font-size: 40px;
  font-weight:500;
  line-height: 45px;
  padding: 60px 0 10px;

  ${screen('md')} {
    font-size: 55px;
    line-height:73px;
    padding: 70px 0 10px;
    max-width: 800px;
  }
`;

export const TextBlockDescription = styled.p`
  font-size:15px;
  font-weight: lighter;
  line-height: 24px;
  color: #242424;
  max-width: 800px;
  margin:0 auto;
  text-align: center;
  padding:0 0 30px;

  ${screen('md')} {
    font-size: 17.5px;
    line-height:30px;
    padding-bottom: 40px;
  }
`
