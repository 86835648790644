import { screen } from '../../../utils/index'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import homeBGLines from '../../../images/pageAssets/background-lines-new.svg'

export const HomeContainer = styled.div`
  background-image: url(${homeBGLines});
  ${tw`
    bg-center
    bg-cover
  `}
`
export const ContainerTwoUp = styled.div`
 ${tw`
 pt-3
 pb-3
 `}
`;

export const secondaryTitleClasses = `
  ${tw`
    font-sans
    leading-none
  `}
  ${screen('sm')} {
    font-size: 23px;
  }
`
export const SecondaryTitleClassesFour = styled.h3`
  ${tw`
    font-bold
    leading-relaxed
    font-sans 
    mb-2
    p-0
    text-31-1/2px
    md:text-40px
    color-charcoal
  `}
`

export const AuthorSectionContainer = styled.div`
  pading-bottom: 50px;
`

export const SecondaryTitleFiveUp = styled.h3`
${tw`
  m-0
  pr-2
  md:pt-6
  pb-3
  pt-3
  md:pt-6
  font-sans   
  font-bold  
  leading-relaxed   
  text-left
  text-charcoal   
  text-xl
  md:text-23px
` } 

  ${screen('sm')} {
    grid-template-columns: repeat(5, 1fr);
    margin:0;
    padding:50px 0 25px 0;
  }
`;

export const GreySectionContainer = styled.div`
  background: #f1f2f4;
  margin-left: -24px;
  margin-right: -24px;
  padding: 0 22px;

  ${screen('xl')} {
    margin-left: 0;
    margin-right: 0;
  }
`;

export const SectionContainerTextFive = styled.div`
  margin: 0 ;

  ${screen('lg')} {
    padding: 0 ;
  }
`;

export const FiveUpCardsDescription = styled.div`
  p {
    &:not(:last-child) {
      padding-bottom: 20px;
    }
    &:empty {
      display: none;
    }
  }
`;

export const SectionContainerTextFour = styled.div`
  margin: 0 ;
  padding-top: 40px;

  ${screen('lg')} {
    padding-top: 50px;
  }
`;

export const SectionContainerTextTwo = styled.div`
  margin: 0;

  ${screen('lg')} {
    padding: 0;
  }
`;

export const SectionContainerCardsFour = styled.div`
  padding: 0 0 60px;

  ${screen('lg')} {
    padding: 0 0 60px;
  }
`;
