import styled from 'styled-components'
import { screen } from '../../../utils/'
import tw from 'tailwind.macro'

export const TextBlockWithColumnsWrapper = styled.div`
${tw`
  flex
  flex-col
  items-center
  `}
`;

export const TextBlockWithColumnsWrapperUp = styled.div`
  padding: 45px 0 25px;

  ${screen('md')} {
    padding: 60px 0 25px;
  }
`;

export const TextBlockWithColumnsWrapperTitle = styled.h3`
${tw`
  pb-3
  pt-0
  font-sans   
  font-bold  
  leading-relaxed   
  text-left
  text-charcoal   
  text-xl 
  md:text-23px
` }  
`;

export const TextBlockWithColumnsWrapperDescription = styled.div`
  ${tw`
    mb-3
    md:mb-6 
    font-sans   
    font-thin   
    leading-relaxed   
    text-base   
    text-charcoal   
    lg:text-17-1/2px
    ` }

  p {
    &:not(:last-child) {
      padding-bottom: 20px;
    }
    &:empty {
      display: none;
    }
  }
`;

export const TextBlockWithColumnsWrapperDown = styled.div`
${tw`
  flex
  flex-col
  w-full
  pb-6
  md:flex
  md:flex-row
  md:justify-between
` } 

${screen('md')} {
  padding-bottom: 12px;
  }
`;

export const TextBlockWithColumnsWrapperLeft = styled.div`
${tw`
  w-full
  md:w-1/2
` }
`;

export const TextBlockWithColumnsWrapperRight = styled.div`
${tw`
w-full
md:w-1/2
mt-1
md:m-0
`}
`;

export const TextBlockWithColumnsWrapperTitleList = styled.div`
${tw`
      pb-2
      text-lg
      font-bold 
      color-charcoal
      leading-relaxed
    ` }  
`;

export const TextBlockWithColumnsWrapperList = styled.div`
  ${tw`
  mb-6 
  font-sans   
  font-thin   
  leading-relaxed   
  text-charcoal   
  text-base   
  lg:text-17-1/2px
` }


`;
