import React from 'react'
import {
  TextBlockWrapper,
  TextBlockTitle,
  TextBlockDescription,
  TextBlockSubtitle
} from './blocks'

const TextBlock = ({ title, subtitle, description }) => (
  <TextBlockWrapper>
    <TextBlockTitle><span className="font-sans font-bold">{title}</span></TextBlockTitle>
    <TextBlockSubtitle>{subtitle}</TextBlockSubtitle>
    <TextBlockDescription>{description}</TextBlockDescription>
  </TextBlockWrapper>
)

export default TextBlock
