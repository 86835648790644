import React from 'react'
import styled from 'styled-components'
import tw from 'tailwind.macro'
import className from 'classnames'

// components
import Button from '../Buttons/Button'
import { Link } from 'gatsby'

const Bar = styled.div`
  height: 8px;
  width: 100%;
`

const ContainerText = styled.div`
  padding: 0 20px 45px;
  p {
    ${tw`
    font-thin
    font-sans
    leading-relaxed 
    text-center
    text-base   
    text-charcoal
    lg:text-17-1/2px
  `}
  }
`

const Image = styled.div`
  aling-items:center;
  justify-content: center;
  display: flex;
  padding: 30px 0 30px;


  img{
    height:45px;
`
const Description = styled.div`
  ${tw`
    font-thin
    font-sans
    leading-relaxed 
    text-center
    text-base   
    text-charcoal
    lg:text-lg
  `}

  p:not(:last-child),
  img:not(:last-child) {
    ${tw`
      mb-3
    `}
  }

  a {
    ${tw`
      text-green-3
      font-bold
    `}
  }
`

const someClasses = 'px-0 pb-0 lg:px-0 lg:pb-0'

const NftFourCards = ({ image, icon, title, description, button }) => {
  const LinkComponent = button ? (button.external ? 'a' : Link) : null

  return (
    <div className="gradient-top-card bg-white card-shadow h-full relative">
      <Bar className="purple-to-orange-horizontal"></Bar>
      {image && image.src && (
        <div>
          <Image>
            <img src={image.src} alt={image.alt} />
          </Image>
        </div>
      )}
      <ContainerText>
        <div
          className={className(someClasses, {
            'pt-4 md:pt-6': !image,
          })}
        >
          <div className="flex items-center">
            {icon && (
              <span
                className="mr-0 lg:mr-0"
                dangerouslySetInnerHTML={{ __html: icon }}
              ></span>
            )}
            <h4 className="font-sans font-bold text-black leading-tight text-xl lg:text-3xl">
              {title}
            </h4>
          </div>
          <Description
            className="mb-1 lg:mb-1"
            dangerouslySetInnerHTML={{ __html: description }}
          />
          {button && (
            <div className="absolute pb-4 px-2 lg:px-4 lg: pin-b pin-x">
              <LinkComponent
                className="no-underline"
                to={button.route}
                href={button.route}
                target={button.external ? '_blank' : null}
              >
                <Button>{button.text}</Button>
              </LinkComponent>
            </div>
          )}
        </div>
      </ContainerText>
    </div>
  )
}

export default NftFourCards
