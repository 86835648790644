import React from 'react'

// components
import NftFourCards from '../../General/Cards/NftFourCards'
import FiveUpCards from '../../General/Cards/FiveUpCards'
import FourUp from '../../Layouts/FourUp/FourUp'
import FiveUp from '../../Layouts/FiveUp/FiveUp'
import TwoUp from '../../Layouts/TwoUp/TwoUp'
import TextDescriptionCta from '../../General/TextDescriptionCta'
import SectionContainer from '../../General/SectionContainer'
import TextWithIcons from '../../General/TextWithIcons'
import TripleCard from '../../General/Cards/TripleCard'
import StoryCardFeed from '../../General/StoryCardFeed'
import TextBlock from '../../General/TextBlock'
import TextBlockWithColumns from '../../General/TextBlockWithColumns'
import HubspotForm from '../../General/HubspotForm'
import AuthorQuote from '../../General/AuthorQuote'

import {
  GreySectionContainer,
  secondaryTitleClasses,
  SecondaryTitleClassesFour,
  SecondaryTitleFiveUp,
  AuthorSectionContainer,
  SectionContainerTextFive,
  SectionContainerTextFour,
  SectionContainerTextTwo,
  SectionContainerCardsFour,
  FiveUpCardsDescription,
  ContainerTwoUp,
} from './blocks'

export const drawComponent = (component) => {
  switch (component.__typename) {
    case 'PrismicNftBodyHubspotEmbed':
      return (
        <div className="my-5 lg:my-8" key={component.id}>
          <SectionContainer>
            <HubspotForm formId={component.primary.form_id} />
          </SectionContainer>
        </div>
      )
    case 'PrismicNftBodyHero':
      return (
        <SectionContainer key={component.id}>
          <TextBlock
            title={component.primary.title.text}
            subtitle={component.primary.subtitle.text}
            description={component.primary.description.text}
          />
        </SectionContainer>
      )
    case 'PrismicNftBodyTextWithIcons':
      return (
        <TextWithIcons
          key={component.id}
          text={component.primary.text.text}
          items={component.items.map((item) => ({
            label: item.title.text,
            icon: item.icon.url,
          }))}
        />
      )
    case 'PrismicNftBodyAuthorQuote':
      return (
        <GreySectionContainer key={component.id}>
          <AuthorSectionContainer>
            <div className="relative z-20 my-0 mx-0 pt-3 pb-6">
              <AuthorQuote
                link={component.primary.link.url}
                quote={component.primary.quote.text}
                name={component.primary.name.text}
                authorPicture={component.primary.author_picture.url}
              />
            </div>
          </AuthorSectionContainer>
        </GreySectionContainer>
      )
    case 'PrismicNftBodyStoryCardFeed':
      return (
        <div className="my-5 lg:my-8" key={component.id}>
          <SectionContainer>
            <StoryCardFeed
              hideIcons
              heading={component.primary.heading.text}
              cards={component.items.map((item) => ({
                title: item.title.text,
                description: item.description.text,
              }))}
            />
          </SectionContainer>
        </div>
      )
    case 'PrismicNftBodyTwoButtons':
      return (
        <GreySectionContainer key={component.id}>
          <SectionContainer>
            <SectionContainerTextTwo>
              <ContainerTwoUp>
                <TwoUp>
                  {component.items.map((item) => {
                    return (
                      <TextDescriptionCta
                        {...{
                          title: item.title.text,
                          description: item.description.text,
                          button: {
                            external: !!item.button.target,
                            text: 'LEARN MORE',
                            route: item.button.url,
                          },
                        }}
                      />
                    )
                  })}
                </TwoUp>
              </ContainerTwoUp>
            </SectionContainerTextTwo>
          </SectionContainer>
        </GreySectionContainer>
      )
    case 'PrismicNftBodyTextWithColumns':
      return (
        <SectionContainer key={component.id}>
          <TextBlockWithColumns
            title={component.primary.title.text}
            description={component.primary.description.html}
            columns={component.items.map((item) => ({
              title: item.title.text,
              list: item.list.raw,
            }))}
          />
        </SectionContainer>
      )
    case 'PrismicNftBodyFourUpCards':
      return (
        <GreySectionContainer key={component.id}>
          <SectionContainer>
            <SectionContainerTextFour>
              <SecondaryTitleClassesFour>
                {component.primary.title.text}
              </SecondaryTitleClassesFour>
              <p className="font-sans font-light text-lg leading-normal mb-3 lg:text-lg lg:mb-5">
                {component.primary.description.text}
              </p>
              <SectionContainerCardsFour>
                <FourUp>
                  {component.items.map((card) => {
                    return (
                      <NftFourCards
                        image={{ src: card.image.url, alt: card.image.alt }}
                        description={card.description.html}
                      />
                    )
                  })}
                </FourUp>
              </SectionContainerCardsFour>
            </SectionContainerTextFour>
          </SectionContainer>
        </GreySectionContainer>
      )
    case 'PrismicNftBodyThreeUpCards':
      return (
        <TripleCard
          key={component.id}
          title={component.primary.title.text}
          subtitle={component.primary.description.text}
          items={component.items.map((item) => ({
            image: item.image.url,
            title: item.title.text,
            description: item.description.text,
            link: item.link,
            author: {
              picture: item.author_picture.url,
              link: null,
              name: item.author_name.text,
              company: item.author_company.text,
            },
          }))}
        />
      )
    case 'PrismicNftBodyFiveUpCards':
      return (
        <GreySectionContainer key={component.id}>
          <SectionContainer>
            <SectionContainerTextFive>
              <SecondaryTitleFiveUp className={secondaryTitleClasses}>
                {component.primary.title.text}
              </SecondaryTitleFiveUp>
              <FiveUpCardsDescription
                className="font-sans font-thin text-base leading-relaxed text-charcoal mb-5 lg:text-lg lg:mb-5  px-0 lg:px-0"
                dangerouslySetInnerHTML={{
                  __html: `<div>${component.primary.description.html}</div>`,
                }}
              ></FiveUpCardsDescription>
              <FiveUp>
                {component.items.map((card) => {
                  return (
                    <FiveUpCards
                      image={{ src: card.image.url, alt: card.image.alt }}
                      button={{
                        text: 'READ MORE',
                        route: card.link.url,
                        external: card.link.target === '_blank',
                      }}
                    />
                  )
                })}
              </FiveUp>
            </SectionContainerTextFive>
          </SectionContainer>
        </GreySectionContainer>
      )
    default:
      return null
  }
}
