import React from 'react'
import classNames from 'classnames'
import SmartLink from '../SmartLink'
import Button from '../Buttons/Button'
import GradientButton from '../Buttons/GradientButton'
import styled from 'styled-components'
import { screen } from '../../../utils/index'
import tw from 'tailwind.macro'

const PrimaryHeading = styled.div`
  ${tw`
    font-sans
    font-bold
    text-charcoal
    leading-none
    mb-2
    p-0
    text-31-1/2px
    md:text-40px
  `}
`

const StoryCardFeed = ({ heading, cards, button }) => (
  <div>
    <PrimaryHeading>{heading}</PrimaryHeading>
    <div>
      {cards.map((card) => {
        return (
          <div
            className="relative bg-white lg:flex items-center px-4 py-4 shadow-md mb-4"
            key={card.title}
          >
            <div className="purple-to-orange-vertical absolute pin-y pin-l w-2"></div>
            <div className="lg:w-1/5">
              <div className="font-sans font-bold text-lg leading-tight">{card.title}</div>
            </div>
            <div className={classNames('lg:py-0 py-3 lg:px-4 px-0', { 'lg:w-4/5': !card.button }, { 'lg:w-3/5': card.button })}>
              <p className="font-sans font-thin leading-relaxed text-base text-charcoal">{card.description}</p>
            </div>
            {card.button && (
              <div className="lg:w-1/5 ml-auto">
                <SmartLink
                  className="no-underline"
                  to={card.button.route}
                >
                  <Button arrowFlushRight textNoShrink>
                    {card.button.text}
                  </Button>
                </SmartLink>
              </div>
            )}
          </div>
        )
      })}
    </div>
    {button && (
      <div className="mt-2">
        <SmartLink className="no-underline" to={button.route}>
          <GradientButton>
            {button.text}
          </GradientButton>
        </SmartLink>
      </div>
    )}
  </div>
)

export default StoryCardFeed
