import React from 'react'

// components
import GradientButton from '../Buttons/GradientButton'
import { Link } from 'gatsby'
import {
  TextDescriptionCtaContainer,
  ContainerText,
  Description,
  Title,
  ButtonLink,
  ButtonText
} from './blocks'

const TextDescriptionCta = ({ title, description, button }) => {
  const LinkComponent = button ? button.external ? 'a'  : Link : null

  return (
    <div className="h-full">
      <TextDescriptionCtaContainer>
        <ContainerText>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </ContainerText>
        <ButtonLink>
          {button && (
            <LinkComponent
              className="no-underline"
              to={button.route}
              href={button.route}
              target={button.external ? '_blank' : null}
              >
                <GradientButton><ButtonText>{button.text}</ButtonText></GradientButton>
            </LinkComponent>
          )}
        </ButtonLink>
      </TextDescriptionCtaContainer>
    </div>
  )
}

export default TextDescriptionCta
