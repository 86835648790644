import React from 'react'
import styled from 'styled-components'
import { screen } from '../../../utils/index'
import className from 'classnames'

// components
import Button from '../Buttons/Button'
import { Link } from 'gatsby'

const ContainerCardWithBotton = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: #fff;
  height: 100%;
`

const ContainerText = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 0 25px;
`

const ButtonLink = styled.div`
  padding: 0;
`

const Image = styled.div`
  aling-items: center;
  justify-content: center;
  display: flex;
  padding: 53px 0 35px;

  img {
    max-height: 75px;
    width: 170px;
    object-fit: contain;
  }
`

const ButtonText = styled.div`
  font-size: 12px;

  ${screen('lg')} {
    font-size: 13px;
    margin-right: -10px;
  }
`

const someClasses = ''

const FiveUpCards = ({ image, icon, title, button }) => {
  const LinkComponent = button ? (button.external ? 'a' : Link) : null

  return (
    <div className="gradient-top-card card-shadow h-full relative">
      <ContainerCardWithBotton>
        {image && image.src && (
          <div>
            <Image>
              <img src={image.src} alt={image.alt} />
            </Image>
          </div>
        )}
        <ContainerText>
          <div
            className={className(someClasses, {
              'pt-4 md:pt-6': !image,
            })}
          >
            <div className="flex items-center">
              {icon && (
                <span
                  className="mr-0 lg:mr-0"
                  dangerouslySetInnerHTML={{ __html: icon }}
                ></span>
              )}
              <h4 className="font-sans font-bold text-black leading-tight text-xl lg:text-5xl">
                {title}
              </h4>
            </div>
            <ButtonLink>
              {button && (
                <div className="">
                  <LinkComponent
                    className="no-underline"
                    to={button.route}
                    href={button.route}
                    target={button.external ? '_blank' : null}
                  >
                    <Button>
                      <ButtonText>{button.text}</ButtonText>
                    </Button>
                  </LinkComponent>
                </div>
              )}
            </ButtonLink>
          </div>
        </ContainerText>
      </ContainerCardWithBotton>
    </div>
  )
}

export default FiveUpCards
