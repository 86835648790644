import styled from 'styled-components'
import { screen } from '../../../utils/'
import tw from 'tailwind.macro'



export const TextDescriptionCtaContainer = styled.div`
  ${tw`
    flex
    flex-col
    h-full
    m-0
    pt-0
    md:pt-2
    pb-2
  `}
  background: #f1f2f4;
`;

export const ContainerText = styled.div`
${tw`
  h-full
`}  
`;

export const Description = styled.p`
  ${tw`
    mb-1
    md:mb-3
    font-sans   
    font-thin   
    leading-relaxed   
    text-base   
    text-charcoal   
    lg:text-17-1/2px
` }


`;

export const Title = styled.p`
  ${tw`
    pt-0
    pb-1
    font-sans   
    font-bold  
    leading-relaxed   
    text-xl
    text-left
    text-charcoal   
    md:text-23px
  ` }
  ${screen('md')} {
  padding-top: 20px;
  }
`;

export const ButtonLink = styled.div`
  ${tw`
  pb-0
  pt-1
  `}

  ${screen('md')} {
  padding-top: 21px;
  padding-bottom: 20px;
  }
`;

export const ButtonText = styled.div`
  font-size: 13px;
  font-weight: lighter;
`;
