import React from 'react'

// components
import PageWrap from '../../Layouts/Global/PageWrap'
import NoTranslation from '../../Templates/NoTranslation'

import { HomeContainer } from './blocks'
import { drawComponent } from './renderer'
import SectionContainer from '../../General/SectionContainer/SectionContainer.js'
import TextBlock from '../../General/TextBlock'

const backgrounds = {
  desktop: null,
  mobile: null,
  mobile2x: null,
}

const NFTPage = ({ data }) => {
  const { prismicNft } = data
  const seo = {
    title:
      prismicNft.data && prismicNft.data.meta_title
        ? prismicNft.data.meta_title.text
        : null,
    description:
      prismicNft.data && prismicNft.data.meta_description
        ? prismicNft.data.meta_description
        : null,
  }
  // handle locales without data
  if (!prismicNft) return <NoTranslation></NoTranslation>
  const components = prismicNft.data.body
  const hero = components.find((el) => el.__typename === 'PrismicNftBodyHero')

  return (
    <PageWrap seo={seo}>
      {hero ? (
        <SectionContainer key={hero.id}>
          <TextBlock
            title={hero.primary.title.text}
            subtitle={hero.primary.subtitle.text}
            description={hero.primary.description.text}
          />
        </SectionContainer>
      ) : null}
      <HomeContainer>
        {components && components.length > 0
          ? components.map((component) =>
              component.__typename !== 'PrismicNftBodyHero'
                ? drawComponent(component)
                : null
            )
          : null}
      </HomeContainer>
    </PageWrap>
  )
}

export default NFTPage
