import React from 'react'
import SectionContainer from '../SectionContainer'
import SmartLink from '../../General/SmartLink'
import Button from '../../General/Buttons/Button'
import {
  SectionContainerAuthorQuoteLink,
  SectionContainerImage,
  SectionContainerTextAuthor,
  SectionContaierTextAuthorQuote,
  SectionContainerQuote,
  SectionContainerLink,
  SectionContainerAuthor,
} from './blocks'

function AuthorQuote ({ link, quote, name, authorPicture }) {
  return (
    <SectionContainer>
      <SectionContainerAuthorQuoteLink>
        <SectionContainerImage>
            <img src={authorPicture} alt="Author" />
        </SectionContainerImage>
        <SectionContainerTextAuthor>
          <SectionContaierTextAuthorQuote>
            <SectionContainerQuote>
              <h4>{quote}</h4>
            </SectionContainerQuote>
            <SectionContainerAuthor>
              <p>– {name}</p>
            </SectionContainerAuthor>
            <SectionContainerLink>
              <SmartLink to={link} className="no-underline">
                <Button>Read More</Button>
              </SmartLink>
            </SectionContainerLink>
          </SectionContaierTextAuthorQuote>
        </SectionContainerTextAuthor>
      </SectionContainerAuthorQuoteLink>
    </SectionContainer>
  )
}

export default AuthorQuote
