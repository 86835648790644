import React from 'react'
import styled from 'styled-components'
import { screen } from '../../../utils/index'

const GridWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 10px;
  margin:0;
  padding: 0 0 40px;

  ${ screen('sm') } {
    grid-template-columns: repeat(2, 1fr);
    margin:0;
  }

  ${ screen('lg') } {
    grid-template-columns: repeat(5, 1fr);
    margin:0;
  }
`

const FiveUp = ({ children, noGaps }) => (
  <GridWrap style={ noGaps ? { gridGap: '0px' } : {} }>
    { children.map((child, i) => (
      <div key={ `five-up-item-${ i }`}>{ child }</div>
    )) }
  </GridWrap>
)

export default FiveUp
